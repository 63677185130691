import type { DraftContent } from '@wix/ricos';

export const addDefaultTextAlignment = (
  content: DraftContent,
  textAlignment: 'right' | 'left',
) => {
  const blocksToAlign = [
    'unstyled',
    'blockquote',
    'header-one',
    'header-two',
    'header-three',
    'header-four',
    'header-five',
    'header-six',
    'code-block',
    'unordered-list-item',
    'ordered-list-item',
  ];

  return {
    ...content,
    blocks: content.blocks.map((b) => {
      if (blocksToAlign.includes(b.type)) {
        return {
          ...b,
          data: {
            textAlignment,
            ...b.data,
          },
        };
      }

      return b;
    }),
  };
};
