import { useMemo } from 'react';
import { ensureRicosContent, ensureDraftContent } from '@wix/ricos';
import { addDefaultTextAlignment } from './addDefaultTextAlignment';

export function useRicosContent(details?: string, isRightAlignment?: boolean) {
  return useMemo(() => {
    return getRicosContent(details, isRightAlignment);
  }, [details, isRightAlignment]);
}

export const getRicosContent = (
  details?: string,
  isRightAlignment?: boolean,
) => {
  if (!details) {
    return null;
  }

  return ensureRicosContent(
    addDefaultTextAlignment(
      ensureDraftContent(JSON.parse(details)),
      isRightAlignment ? 'right' : 'left',
    ),
    { ignoreUnsupportedValues: true },
  );
};
