import { intervalToDuration } from 'date-fns';

export function formatProgramVideoDuration(t: Function, ms: number): string {
  const duration = intervalToDuration({ start: 0, end: ms });
  // { minutes: 30, seconds: 7 }
  const zeroPad = (num) => String(num).padStart(2, '0');

  return t('live-site.step-type-subtitle.video-duration', {
    minutes: zeroPad(duration.minutes),
    seconds: zeroPad(duration.seconds || 1),
  });
}
